<template>
  <div>
    <b-card
      no-body
      class=""
    >
      <b-card-body>
        <b-row
          v-if="!isMobile()"
          class="mb-2"
        >
          <b-col
            cols="12"
            md="6"
            class=""
          >
            <span class="weight-500 fs-15">Forms</span>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class=""
          >
            <span class="weight-500 fs-15">Status</span>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class=""
          >
            <span class="weight-500 fs-15">Signed PDF Copy</span>
          </b-col>
        </b-row>
        <div
          v-for="(form, key) in requestForms"
          :key="key"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="hover-td"
            >
              <div
                v-if="isMobile()"
              >
                <span class="weight-500 fs-15">Forms</span>
              </div>
              <div
                class=""
              >
                <span class="">{{ form.formName }}</span>
                <b-button
                  v-b-tooltip.hover.top="'Edit form'"
                  variant="flat-primary"
                  class="ml-75 wf-action-button"
                  style="float: none; margin-top: 0 !important;"
                  :to="{ name: 'operation-service-requests-form-view', params: { id: serviceRequestId }, query: { form: form._id } }"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-if="form.status == 'Signed and Sent' && form.pdfs.length"
                  v-b-tooltip.hover.top="'Download without password'"
                  variant="flat-primary"
                  class="ml-75 wf-action-button"
                  style="float: none; margin-top: 0 !important;"
                  @click="downloadSRForm(form._id, form.pdfs[0].name)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-if="['To prepare', 'Signing', 'Ready to sign'].includes(form.status)"
                  v-b-tooltip.hover.top="'Download PDF'"
                  variant="flat-primary"
                  class="ml-75 wf-action-button"
                  style="float: none; margin-top: 0 !important;"
                  @click="downloadSRFormPDF(form._id, form.formName)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class=""
            >
              <div
                v-if="isMobile()"
                class="mt-75"
              >
                <span class="weight-500 fs-15">Status</span>
              </div>
              <b-badge
                :variant="`light-${resolveFormStatusVariant(form.status)}`"
                :to="{ name: 'operation-service-requests-form-view', params: { id: serviceRequestId }, query: { form: form._id } }"
                style="padding: 4px 9px; font-size: 14px; line-height: 18px; font-weight: 400;"
                :class="{ 'mt-50': isMobile() }"
              >
                {{ form.status }}
              </b-badge>
              <br>
              <span v-if="form.pdfSentDate">
                {{ dateFormatWithTime(form.pdfSentDate) }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="list-item"
            >
              <div
                v-if="isMobile()"
                class="mt-75"
              >
                <span class="weight-500 fs-15">Signed PDF Copy</span>
              </div>
              <div v-if="form.pdfs.length">
                <app-collapse
                  v-if="form.pdfs.length > 1"
                  class="reverse-icon"
                >
                  <app-collapse-item title="Urn Repository Form">
                    <template
                      #header
                    >
                      <b-row
                        class="full-width"
                      >
                        <!-- Content Left -->
                        <b-col
                          class="content-header-left"
                          cols="12"
                          md="12"
                        >
                          <div
                            class="attachment-item"
                          >
                            <b-img
                              left
                              height="40"
                              :src="require('@/assets/images/admin/doc_file.png')"
                              alt="Left image"
                            />
                            <strong>
                              <b-link
                                :href="form.pdfs[0] ? form.pdfs[0].data : ''"
                                target="_blank"
                              >
                                {{ form.pdfs[0] ? form.pdfs[0].name : '' }}
                              </b-link>
                            </strong>

                            <b-badge
                              variant="warning"
                              class="ml-auto"
                            >
                              Latest
                            </b-badge>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                    <b-row
                      v-for="(pdf, index) in form.pdfs"
                      :key="index"
                      class="full-width"
                    >
                      <b-col
                        v-if="index > 0"
                        class="content-header-left mt-50"
                        cols="12"
                        md="12"
                      >
                        <div
                          class="attachment-item"
                        >
                          <b-img
                            left
                            height="40"
                            :src="require('@/assets/images/admin/doc_file.png')"
                            alt="Left image"
                          />
                          <strong>
                            <b-link
                              :href="pdf ? pdf.data : ''"
                              target="_blank"
                            >
                              {{ pdf ? pdf.name : '' }}
                            </b-link>
                          </strong>
                        </div>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
                <div
                  v-else
                  class="attachment-item mr-28"
                >
                  <b-img
                    left
                    height="40"
                    :src="require('@/assets/images/admin/doc_file.png')"
                    alt="Left image"
                  />
                  <strong>
                    <b-link
                      :href="form.pdfs[0] ? form.pdfs[0].data : ''"
                      target="_blank"
                    >
                      {{ form.pdfs[0] ? form.pdfs[0].name : '' }}
                    </b-link>
                  </strong>
                  <b-badge
                    variant="warning"
                    class="ml-auto"
                  >
                    Latest
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-row>
          <hr
            v-if="key < (requestForms.length - 1)"
            class=""
          >
        </div>

        <h3 class="my-2">
          Uploaded Documents
        </h3>

        <div
          v-if="uploadedFiles.length || ancestralFiles.length"
        >
          <b-row
            v-for="(block, index) in ancestralFiles"
            :key="'block' + index"
          >
            <!-- Content Left -->
            <b-col
              v-for="(file, key) in block"
              :key="key"
              class="content-header-left mb-2"
              cols="12"
              md="4"
            >
              <div
                v-if="file.data && file.data.startsWith('http')"
              >
                <div
                  class="attachment-item"
                >
                  <b-img
                    v-if="file.type.includes('image')"
                    left
                    height="40"
                    :src="require('@/assets/images/admin/doc_jpg.png')"
                    alt="Left image"
                  />
                  <b-img
                    v-if="file.type.includes('application')"
                    left
                    height="40"
                    :src="require('@/assets/images/admin/doc_file.png')"
                    alt="Left image"
                  />
                  <strong>
                    <b-link
                      :href="file ? file.data : ''"
                      target="_blank"
                      class="high_opacity"
                    >
                      {{ file ? file.name : '' }}
                    </b-link>
                  </strong>
                  <span>{{ file ? `${file.size}mb` : '' }}</span>
                  <!-- <feather-icon
                          icon="XIcon"
                          class="remove__doc"
                          size="16"
                          @click="removeAttachment(index, file.data, block._id)"
                        /> -->
                </div>
                <div class="doc__desc">
                  <span v-if="file.description">
                    {{ file.description }}
                  </span>
                  <!-- <span v-else>
                          <button @click="updateDescription(index, key, block._id)">Add a description</button>
                        </span>
                        <span
                          v-if="file.description"
                          class="edit__desc"
                        >
                          <b-button
                            variant="flat-primary"
                            @click="updateDescription(index, key, block._id)"
                          >
                            <feather-icon
                              icon="EditIcon"
                              class="mr-50"
                              size="18"
                            />
                          </b-button>
                        </span> -->
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(block, index) in uploadedFiles"
            :key="index"
          >
            <!-- Content Left -->
            <b-col
              v-for="(file, key) in block.values"
              :key="key"
              class="content-header-left mb-2"
              cols="12"
              md="4"
            >
              <div
                v-if="file.data && file.data.startsWith('http')"
              >
                <div
                  class="attachment-item"
                >
                  <b-img
                    v-if="file.type.includes('image')"
                    left
                    height="40"
                    :src="require('@/assets/images/admin/doc_jpg.png')"
                    alt="Left image"
                  />
                  <b-img
                    v-if="file.type.includes('application')"
                    left
                    height="40"
                    :src="require('@/assets/images/admin/doc_file.png')"
                    alt="Left image"
                  />
                  <strong>
                    <b-link
                      :href="file ? file.data : ''"
                      target="_blank"
                      class="high_opacity"
                    >
                      {{ file ? file.name : '' }}
                    </b-link>
                  </strong>
                  <span>{{ file ? `${file.size}mb` : '' }}</span>
                  <!-- <feather-icon
                    icon="XIcon"
                    class="remove__doc"
                    size="16"
                    @click="removeAttachment(index, file.data, block._id)"
                  /> -->
                </div>
                <div class="doc__desc">
                  <span v-if="file.description">
                    {{ file.description }}
                  </span>
                  <!-- <span v-else>
                    <button @click="updateDescription(index, key, block._id)">Add a description</button>
                  </span>
                  <span
                    v-if="file.description"
                    class="edit__desc"
                  >
                    <b-button
                      variant="flat-primary"
                      @click="updateDescription(index, key, block._id)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                        size="18"
                      />
                    </b-button>
                  </span> -->
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <span
          v-else
          class="align-middle"
        >
          No uploaded document yet.
        </span>
      </b-card-body>
    </b-card>

    <b-modal
      :id="'modal-attachment-description-'+randID"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col
          md="12"
          class=""
        >
          <div class="demo-inline-spacing ml-auto">
            <b-button
              variant="flat-primary"
              @click="closeDescriptionModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!description || attachmentKey === ''"
              @click="saveDescription()"
            >
              <span class="align-middle">Save</span>
            </b-button>
          </div>
        </b-col>
      </b-row> -->
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BBadge, VBTooltip, BButton, BImg, BLink, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    BImg,
    BLink,
    BFormTextarea,

    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    formOptions: {
      type: [Array, null],
      default: null,
    },
    serviceRequestForms: {
      type: [Array, null],
      default: null,
    },
    fileUploadTasks: {
      type: [Array, null],
      default: null,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
    fileUploadBlock: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      randID: uuidv4(),
      uploadedFiles: [],
      ancestralFiles: [],
      description: '',
      indexKey: '',
      attachmentKey: '',
      taskBlock: '',
    }
  },
  computed: {
    requestForms() {
      const formArray = []
      const forms = this.serviceRequestForms.filter(f => f.serviceRequest === this.serviceRequestId)
      if (forms.length) {
        const groupArray = forms.reduce((r, a) => {
          // eslint-disable-next-line no-param-reassign
          r[a.taskBlockID] = r[a.taskBlockID] || []
          r[a.taskBlockID].push(a)
          return r
        }, Object.create(null))

        Object.entries(groupArray).forEach(group => {
          const firstForm = group[1][0]
          const taskBlock = firstForm.serviceRequestTask.taskBlocks.find(o => o._id === firstForm.taskBlockID)
          taskBlock.fieldValues[3].value.forEach(field => {
            const formObj = {
              _id: '',
              status: '',
              pdfSentDate: '',
              pdfs: [],
            }
            formObj.formName = this.formName(field)
            // eslint-disable-next-line eqeqeq
            const reqForm = this.serviceRequestForms.find(o => o.serviceRequestTask._id == firstForm.serviceRequestTask._id && o.formID == field && o.taskBlockID == firstForm.taskBlockID)
            if (reqForm) {
              formObj._id = reqForm._id
              formObj.status = reqForm.status
              formObj.pdfSentDate = reqForm.pdfSentDate
              formObj.pdfs = reqForm.pdfs.reverse()
            }

            formArray.push(formObj)

            if (this.formNameOnly(field) === 'Ancestral Tablets Engraving Notice') {
              const ownEngravingImageObject = reqForm.values.find(o => o.key === 'ownEngravingImage')
              if (ownEngravingImageObject) {
                this.ancestralFiles.push(ownEngravingImageObject.value)
              }
            }
          })
        })
      }
      // this.serviceRequestForms.forEach(field => {
      //   if (field.serviceRequest === this.serviceRequestId) {
      //     const formObj = {}
      //     formObj.formName = this.formName(field.formID)
      //     formObj._id = field._id
      //     formObj.status = field.status
      //     formObj.pdfSentDate = field.pdfSentDate
      //     formObj.pdfs = field.pdfs.reverse()

      //     formArray.push(formObj)
      //     if (formObj.formName === 'Ancestral Tablets Engraving Notice') {
      //       const ownEngravingImageObject = field.values.find(o => o.key === 'ownEngravingImage')
      //       if (ownEngravingImageObject) {
      //         this.ancestralFiles.push(ownEngravingImageObject.value)
      //       }
      //     }
      //   }
      // })
      return formArray
    },
  },
  created() {
    // this.serviceRequestForms.forEach(field => {
    //   const formObj = {}
    //   formObj._id = field._id
    //   formObj.formName = this.formName(field.formID)
    //   formObj.status = field.status
    //   formObj.pdfSentDate = field.pdfSentDate
    //   formObj.pdfs = field.pdfs.reverse()

    //   this.formArray.push(formObj)
    // })

    const fileArray = []
    this.fileUploadTasks.forEach(task => {
      if (task.serviceRequest === this.serviceRequestId) {
        task.taskBlocks.forEach(block => {
          const obj = {
            _id: block._id,
            values: (block.inputValues && block.inputValues.length) ? block.inputValues[0].value : [],
          }
          fileArray.push(obj)
        })
      }
    })
    this.uploadedFiles = fileArray
  },
  methods: {
    downloadSRForm(id, name) {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${id}/detail`)
      formData.append('landscape', false)
      formData.append('pageRanges', 1)

      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          link.click()

          link.remove()
        })
    },
    downloadSRFormPDF(id, name) {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${id}/detail`)
      formData.append('landscape', false)
      formData.append('pageRanges', 1)

      this.$http.post('operation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${name.replace(/[/ ]/gi, '-')}.pdf`
          link.click()

          link.remove()
        })
    },
    formName(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        let fName = form.name
        if (form.formNumber && form.formNumber !== '-') {
          fName += ` (${form.formNumber})`
        }
        if (form.revision && form.revision !== '-') {
          fName += ` (${form.revision})`
        }
        return fName
      }

      return ''
    },
    formNameOnly(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        return form.name
      }

      return ''
    },
    resolveFormStatusVariant(status) {
      if (status === 'Signed and Sent') return 'success'
      if (status === 'Not required') return 'secondary'
      if (status === 'To prepare') return 'primary'
      return 'warning'
    },

    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', `modal-attachment-description-${this.randID}`, '')
    },
    updateDescription(index, key, block) {
      this.indexKey = index
      this.attachmentKey = key
      this.taskBlock = block
      this.description = this.uploadedFiles[index].values[key].description
      this.$root.$emit('bv::show::modal', `modal-attachment-description-${this.randID}`, '')
    },
    saveDescription() {
      const url = this.uploadedFiles[this.indexKey].values[this.attachmentKey].data
      const formData = new FormData()
      formData.append('serviceRequest', this.serviceRequestId)
      formData.append('block', this.taskBlock)
      formData.append('description', this.description)
      formData.append('url', url)
      this.$http.post('operation/service-requests/file-task/save-file-description', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const slot = response.data.data.taskBlocks.find(o => o._id === this.taskBlock)
          this.uploadedFiles[this.indexKey].values = slot.inputValues[0].value
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.closeDescriptionModal()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    removeAttachment(index, url, block) {
      const formData = new FormData()
      formData.append('serviceRequest', this.serviceRequestId)
      formData.append('block', block)
      formData.append('url', url)
      this.$http.post('operation/service-requests/file-task/remove-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const slot = response.data.data.taskBlocks.find(o => o._id === this.taskBlock)
          this.$emit('update:inputValues', slot.inputValues)
          this.uploadedFiles[index].values = slot.inputValues[0].value
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
      border-right-width: 1px !important;
  }
  th.no-bg {
    background: transparent !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .list-item .card .card-header.collapsed {
    padding: 0 !important;
  }
  .list-item .card .card-header {
    border-bottom: none !important
  }
  .list-item .attachment-item {
    background: rgba(16, 77, 157, 0.12);
    padding: 10px;
    border-radius: 5px;
  }
  .list-item .full-width {
    width: 100% !important;
  }
  .list-item {
    .collapse-icon [data-toggle=collapse]:after {
      top: 50% !important;
    }
  }
  .list-item .collapse-icon [data-toggle=collapse]:after {
    background-size: 20px !important;
  }
</style>
